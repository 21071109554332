import { APIFilter } from '@/utils/api'
import { toDate, endOfDay } from '@/utils/date'

export default {
  async selectFacturaCliente (Vue, filter, search, sorter, page, idcliente) {
    const apiFilter = new APIFilter()
      .addExact('idcliente', idcliente)
    if (filter.estado.value) {
      apiFilter.addIn(filter.estado.field, filter.estado.value)
    }
    if (filter.fdesde.value) {
      apiFilter.addGTE(filter.fdesde.field, toDate(filter.fdesde.value))
    }
    if (filter.fhasta.value) {
      apiFilter.addLTE(filter.fhasta.field, endOfDay(toDate(filter.fhasta.value)))
    }
    if (search) {
      apiFilter.addNestedFilter(Vue.$online.facturacli.searchFilter(search))
    }
    const resp = await Vue.$api.call('facturacli.select', {
      page,
      filter: apiFilter,
      sorter,
    })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
  async selectFacturaClienteRows (Vue, pks) {
    const apiFilter = new APIFilter()
    apiFilter.addIn('idfacturacli', pks)
    const resp = await Vue.$api.call('facturacli.select', { filter: apiFilter })
    return [resp.data.result.dataset, resp.data.result.metadata]
  },
}
