<template>
  <b10-legend
    :showing.sync="internalShowing"
  >
    <template
      slot="legend"
    >
      <v-simple-table>
        <template
          #default
        >
          <tbody
            v-for="item in DEUDA_CLIENTE.idestados"
            :key="item.idestados"
          >
            <tr>
              <td>
                <v-chip
                  :color="DEUDA_CLIENTE.colores[item]"
                  dark
                >
                  <span
                    class="font-weight-bold subtitle-1"
                  >{{ $options.filters.initials(DEUDA_CLIENTE.descripciones[item]) }}</span>
                </v-chip>
              </td>
              <td>
                {{ DEUDA_CLIENTE.descripciones[item] }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </template>
  </b10-legend>
</template>

<script>
import internalShowingMixin from '@/mixins/internalShowingMixin'
import { DEUDA_CLIENTE } from '@/utils/consts'

export default {
  mixins: [internalShowingMixin],
  data () {
    return {
      DEUDA_CLIENTE
    }
  },
}
</script>
